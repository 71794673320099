<template>
  <div class="py-2 w-full">
    <label v-if="label" :for="id" :aria-label="label" class="block mb-3 text-lg font-albert text-brand-black/70">{{ displayLabel }}</label>
    <select
      :value="modelValue"
      @input="emits('update:modelValue', $event.target.value)"
      :id="id"
      :name="name"
      class="bg-brand-light/10 text-base rounded-[10px] text-brand-black block p-3 w-full appearance-none focus:outline-none border border-brand-light focus:border-brand-dark focus:border focus:ring-1"
    >
      <option value="" selected>{{ placeHolder || '===Select One==' }}</option>
      <option class="" v-for="item in options" :key="item.key" :value="item.key">{{ item.value }}</option>
    </select>
  </div>
</template>

<script setup>
import { onMounted, computed } from 'vue';

const emits = defineEmits(['update:modelValue']);

const props = defineProps({
  label: String,
  id: {
    type: String,
    default: () => `id${Math.random() * 3}`,
  },
  selectedValue: String,
  options: {
    type: Array,
    default: () => [],
  },
  placeHolder: String,
  modelValue: String | Number,
  isRequired: {
    type: Boolean,
    default: false,
  },
  name: String,
});

const displayLabel = computed(() => (props.isRequired ? `${props.label} *` : `${props.label}`));

onMounted(() => {
  if (props.isRequired) {
    document.getElementById(props.id)?.setAttribute('required', true);
  }
});
</script>
