<template>
  <div class="py-2 w-full">
    <label v-if="label" :for="labelFor" :aria-label="label" class="block text-lg font-albert text-brand-black/70" :class="[msg ? 'mb-0' : 'mb-3']">{{
      displayLabel
    }}</label>
    <p v-if="msg" class="text-xs font-noraml text-brand-black/40">{{ msg }}</p>
    <div class="flex">
      <span
        v-if="side"
        class="inline-flex items-center px-5 py-2 text-base text-white bg-[#172A49] rounded-l-md border rounded border-r-0 border-gray-300"
      >
        {{ sideIcon }}
      </span>
      <input
        :type="type"
        :id="id"
        :value="modelValue"
        @input="emits('update:modelValue', $event.target.value)"
        class="bg-brand-light/10 text-base rounded-[10px] w-full p-4 appearance-none focus:outline-none border border-brand-light focus:border-brand-dark focus:border focus:ring-1"
        :placeholder="placeholder"
        :minlength="minlength"
        :name="name"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed } from 'vue';

const emits = defineEmits(['update:modelValue']);

const props = defineProps({
  label: String,
  id: {
    type: String,
    default: () => `id${Math.random() * 3}`,
  },
  type: {
    type: String,
    default: 'text',
  },
  placeholder: String,
  labelFor: String,
  modelValue: String | Number,
  msg: String,
  side: Boolean,
  sideIcon: {
    type: String,
    default: '$',
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  minlength: Number,
  name: String,
});

const displayLabel = computed(() => (props.isRequired ? `${props.label} *` : `${props.label}`));

onMounted(() => {
  if (props.isRequired) {
    document.getElementById(props.id)?.setAttribute('required', true);
  }
});
</script>
