<template>
  <div class="flex flex-col justify-items-center gap-28 mt-20 md:mt-28 page-wrap">
    <div class="py-5 w-11/12 max-w-6xl mx-auto px-8 md:px-10 flex flex-col">
      <h2 class="header text-[32px] md:text-[56px] font-medium">Are you looking for top-tier talent to help grow your business?</h2>
      <p>
        Tell us about your hiring need and we'll find the best solution for you. With our global network of experienced Sales, Marketing, Finance,
        Operations, Developer talents, we offer a variety of flexible engagement models.
      </p>
    </div>
    <div class="flex flex-col md:flex-row pb-5 w-11/12 max-w-6xl mx-auto px-8 md:px-10 gap-14">
      <div class="flex flex-start flex-col">
        <p class="font-bold mb-2 md:mb-5">Contact our team to get started</p>
        <p class="mb-4 md:mb-[17rem]">We endeavour to respond as soon as we can</p>
        <div class="">
          <img src="@/assets/images/contact-lady.png" />
        </div>
      </div>
      <div class="flex flex-start md:flex-end flex-col w-full">
        <form v-if="!formSent" class="flex flex-col w-full" id="contact_form">
          <div class="flex flex-col w-full text-red-600" v-if="errorMesage">{{ errorMesage }}</div>
          <div class="flex flex-col md:flex-row md:gap-8 w-full">
            <Input id="first_name" name="first_name" label="First Name" :isRequired="true" v-model="dataForm.first_name" />
            <Input id="last_name" name="last_name" label="Last Name" :isRequired="true" v-model="dataForm.last_name" />
          </div>
          <Input id="company_name" name="company_name" label="Company Name" :isRequired="true" v-model="dataForm.company_name" />
          <Input id="email" name="work_email" label="Email Address" type="email" :isRequired="true" v-model="dataForm.work_email" />
          <Input id="phone" name="phone_no" label="Phone Number" type="tel" :isRequired="true" v-model="dataForm.phone_no" />
          <Select
            id="company_hq"
            name="country_id"
            label="Where’s your Company HQ?"
            :isRequired="true"
            :options="countries"
            v-model="dataForm.country_id"
          />
          <Select
            id="company_service"
            name="company_service"
            label="What Service Are you interested in?"
            :isRequired="true"
            :options="services"
            v-model="dataForm.service"
          />
          <Input id="company_hire" name="role" label="If you are hiring, what type of role are you hiring?" v-model="dataForm.role" />
          <div class="flex flex-col gap-5 w-full mt-3">
            <p class="text-[14px] md:text-lg font-normal">
              You consent to receiving communications from CareerBuddy regarding its products, services, promotions, events and activities. You may
              withdraw your consent at any time by contacting marketing@thecareerbuddy.com. All information submitted to CareerBuddy is subject to our
              Privacy Policy
            </p>
            <button type="button" class="bg-brand-black text-white py-3 px-12 block rounded my-3" v-if="!isSending" @click.prevent="sendForm">
              Submit
            </button>
            <Loading className="px-12 flex rounded mt-3 mx-auto" v-else :isLoading="true" />
          </div>
        </form>
        <div v-else>
          <div class="text-center items-center justify-center w-full mx-auto">
            <div class="mx-auto w-3/5 items-center text-center">
              <img src="@/assets/icons/check.svg" class="self-center text-center" />
              <h3 class="text-brand-green text-[34px] font-semibold">Request sent successfully!</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Input from '@/components/form/Input.vue';
import Select from '@/components/form/Select.vue';
import TextArea from '@/components/form/TextArea.vue';
import Loading from '@/components/Loading.vue';
import { useMeta } from '@/utils/meta';

const store = useStore();

const countries = ref([]);

const services = ref([
  {
    key: 'Head hunting service',
    value: 'Head hunting service',
  },
  {
    key: 'I want to Outsource',
    value: 'I want to Outsource',
  },
  {
    key: 'Talent Marketplace',
    value: 'Talent Marketplace',
  },
  {
    key: 'Embedded Recruiter',
    value: 'Embedded Recruiter',
  },
  {
    key: 'Employer Branding',
    value: 'Employer Branding',
  },
  {
    key: 'Promote my Brand',
    value: 'Promote my Brand',
  },
  {
    key: 'I am not sure. I want to speak to Sales',
    value: 'I am not sure. I want to speak to Sales',
  },
]);

const titles = ref([]);

const isSending = ref(false);

const errorMesage = ref('');

const formSent = ref(false);

const dataForm = ref({
  first_name: '',
  last_name: '',
  phone_no: '',
  work_email: '',
  company_name: '',
  service: '',
  role: '',
  country_id: '',
});

const sendForm = async () => {
  try {
    if (isSending.value === true) return;
    isSending.value = true;
    await store.dispatch('global/sendContactForm', dataForm.value);
    formSent.value = true;
    document.querySelector('.page-wrap')?.scrollIntoView(-200);
  } catch (error) {
    isSending.value = false;
    document.querySelector('.page-wrap')?.scrollIntoView(-200);
    errorMesage.value = error.response?.data?.message || error.message;
  }
};

onMounted(async () => {
  document.querySelector('.page-wrap')?.scrollIntoView(-200);
  const c = (await store.dispatch('global/getCountries')) || [];
  // const s = await store.dispatch('global/getCompanyCategory') || [];
  //const t = (await store.dispatch('global/getJobTitles')) || [];
  countries.value = c.reduce((pv, cv) => {
    const country = {
      key: cv.id,
      value: cv.value,
    };
    pv = [...pv, ...[country]];
    return pv;
  }, []);

  /*services.value = s.reduce((pv, cv) => {
        const service = {
            key: cv.value,
            value: cv.value
        };
        pv = [...pv, ...[service]];
        return pv;
    }, []);

  titles.value = t.reduce((pv, cv) => {
    const title = {
      key: cv.value,
      value: cv.value,
    };
    pv = [...pv, ...[title]];
    return pv;
  }, []);*/
});

useMeta();
</script>

<style scoped></style>
